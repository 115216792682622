export default {
    name: "LandingPage",
    created () {
        this.$nextTick(function () {
            this.getRiclist();
        });
    },
    mounted () {
        this.$nextTick(function () {
            $(".form-control_1").click(function () {
                $(".form-control_1").datepicker("show");
            });
            $(".form-control_2").click(function () {
                $(".form-control_2").datepicker("show");
            });
            //  //改变meta的keywords
            //  $('meta[name="keywords"]').attr('content', "warrant calculator, warrant terms, RHB, malaysia warrants,"+localStorage.getItem("metaKeywords"));
        });
    },
    data () {
        return {
            ricNmae1: null,
            ricNmae2: null,
            exchangeratioTurn: null
        };
    },
    methods: {
        // 初始化
        _info: function (data, Num) {
            let then = this;
            if (Num == 1) {
                $('.Warrant_1').find(".info").click(function () {
                    then.GetMarketData(then.ricNmae1, Num);
                    // $(".ui_widget").find('input').val(data.underlying_name + ' (' + data.dsply_nmll + ')');
                });
            } else if (Num == 2) {
                $('.Warrant_2').find(".info").click(function () {
                    then.GetMarketData(then.ricNmae2, Num);
                    // $(".ui_widget").find('input').val(data.underlying_name + ' (' + data.dsply_nmll + ')');
                });
            }

        },
        // 接收Marke数据
        GetMarketData: function (ric, Num) {
            let then = this;
            $.ajax({
                //请求方式
                type: "POST",
                //发送请求的地址
                url: this.$js.webservice + "GetMarketData",
                //服务器返回的数据类型
                dataType: "json",
                // async: false,
                //发送到服务器的数据，对象必须为key/value的格式，jquery会自动转换为字符串格式
                data: { token: "webkey", type: "warrantdata", ric: ric },
                success: function (data) {
                    //请求成功函数内容
                    if (data.status === "SUCCESS") {
                        if (Num == 1) {
                            then.assignmentMarke(data[ric], data.exchangeratio, Num);
                            then.assignmentPriceMatrix(data[ric], data.exchangeratio, Num);
                        } else if (Num == 2) {
                            then.assignmentMarke(data[ric], data.exchangeratio, Num);
                            then.assignmentPriceMatrix(data[ric], data.exchangeratio, Num);
                        }

                        // then.currencySwitch(data.exchangeratio, data[ric])
                    }
                },
                error: function (jqXHR) {
                    //请求失败函数内容
                }
            });
        },
        // Marke赋值
        assignmentMarke: function (data, exchangeratio, Num) {
            let then = this;
            if (Num == 1) {
                var $information1 = $('.Warrant_1').find(".information");
                var $simulator1 = $('.Warrant_1').find(".simulator");
                $('.Warrant_1').find(".calculator_call_name")
                    .find("span")
                    .text(data.type);
                // Warrant information
                var effective_gearing1 = data.effective_gearing;
                if (effective_gearing1 !== "N/A") {
                    effective_gearing1 =
                        this.$js.formatNumber(effective_gearing1, 1, 0) + " x";
                }
                var sensitivity1 = data.sensitivity;
                if (sensitivity1 !== "N/A") {
                    sensitivity1 = this.$js.formatNumber(sensitivity1, 1, 0) + " ticks";
                }
                $information1
                    .find(".underlying_price")
                    .text(
                        this.$js.curreryRetrun(data.underlying_curr) +
                        " " +
                        this.$js.formatNumber(data.underlying_price, 0, 1)
                    );
                $information1
                    .find(".exercise_price")
                    .text(
                        this.$js.curreryRetrun(data.underlying_curr) +
                        " " +
                        this.$js.formatNumber(data.exercise_price, 0, 1)
                    );
                $information1
                    .find(".conv_ratio")
                    .text(this.$js.formatNumber(data.conv_ratio, 4, 0));
                $information1.find(".maturity").text(data.maturity);
                $information1.find(".effective_gearing").text(effective_gearing1);
                $information1.find(".sensitivity").text(sensitivity1);

                // warrant Simulator
                // Underlying Target Price
                var sPrice1 = Number(data.underlying_price);
                var sEPrice1 = Number(data.exercise_price);
                var sDays1;
                var real_volatility1;
                if (data.v_implied_volatility) {
                    real_volatility1 = Number(data.v_implied_volatility);
                } else {
                    real_volatility1 = 0;
                }
                $simulator1
                    .find(".underlying_price")
                    .val(
                        this.$js.curreryRetrun(data.underlying_curr) +
                        " " +
                        this.$js.formatNumber(data.underlying_price, 0, 0)
                    );
                $simulator1.find(".underlying_price").on("input", function () {
                    if ($(this).val().length === 0) {
                        sPrice1 = 0;
                    } else {
                        sPrice1 = Number($(this).val());
                    }
                    var per1 =
                        ((sPrice1 - Number(data.underlying_price)) /
                            Number(data.underlying_price)) *
                        100;
                    $simulator1.find(".percentage").val(then.$js.formatNumber(per1, 1, 0));
                    $simulator1
                        .find(".percentage")
                        .width($simulator1.find(".percentage").val().length * 10);
                    then.assignmentResult(
                        sPrice1,
                        sEPrice1,
                        sDays1,
                        real_volatility1,
                        data,
                        exchangeratio,
                        Num
                    );
                });
                // Expect to sell on
                var todayDate1 = new Date(new Date().toDateString());
                var saveMaturity1 = data.maturity;
                var endDate1 = new Date(saveMaturity1);
                var selectdate1;
                // 日历选择器
                $(".form-control_1").datepicker("destroy");
                $(".form-control_1")
                    .datepicker({
                        autoclose: true,
                        dateFormat: "dd M y",
                        minDate: todayDate1,
                        maxDate: endDate1,
                        onSelect: function (dateText, inst) {
                            selectdate1 = new Date(dateText);
                            $(".form-control_1").datepicker("setDate", selectdate1);
                            $simulator1
                                .find(".days")
                                .val(parseInt((endDate1 - selectdate1) / (24 * 3600 * 1000)));
                            $simulator1
                                .find(".days")
                                .width($simulator1.find(".days").val().length * 10);
                            sDays1 = parseInt((endDate1 - selectdate1) / (24 * 3600 * 1000)) / 365;
                            then.assignmentResult(
                                sPrice1,
                                sEPrice1,
                                sDays1,
                                real_volatility1,
                                data,
                                exchangeratio,
                                Num
                            );
                        }
                    })
                    .datepicker("setDate", todayDate1);
                $('.Warrant_1').find("#ui-datepicker-div").css({ "overflow-y": "hidden" });
                // Default
                $simulator1.find(".days").val(this.intervalDate(todayDate1, endDate1));
                sDays1 = Number($simulator1.find(".days").val()) / 365;
                $simulator1
                    .find(".days")
                    .width($simulator1.find(".days").val().length * 10);
                $simulator1
                    .find(".percentage")
                    .val(
                        this.$js.formatNumber(
                            ((Number(data.underlying_price) - Number(data.underlying_price)) /
                                Number(data.underlying_price)) *
                            1,
                            1,
                            0
                        )
                    );
                $simulator1
                    .find(".percentage")
                    .width($simulator1.find(".percentage").val().length * 10);
                $simulator1.find(".percentage").on("input", function () {
                    var pNum1 = $(this).val();
                    if ($(this).val().length !== 0) {
                        $(this).width($(this).val().length * 10);
                    } else {
                        $(this).width(10);
                        pNum1 = 0;
                    }
                    var price1 = Number(data.underlying_price) * (1 + Number(pNum1) * 0.01);
                    $simulator1
                        .find(".underlying_price")
                        .val(
                            then.$js.curreryRetrun(data.underlying_curr) +
                            " " +
                            then.$js.formatNumber(price1, 0, 0)
                        );
                    sPrice1 = price1;
                    then.assignmentResult(
                        sPrice1,
                        sEPrice1,
                        sDays1,
                        real_volatility1,
                        data,
                        exchangeratio,
                        Num
                    );
                });
                if (Number($simulator1.find(".days").val()) >= 2) {
                    $simulator1.find(".i_day").text("days");
                } else {
                    $simulator1.find(".i_day").text("day");
                }
                $simulator1.find(".days").on("input", function () {
                    var addDays1 = parseInt($(this).val());
                    if ($(this).val().length !== 0) {
                        $(this).width($(this).val().length * 10);
                    } else {
                        $(this).width(10);
                        addDays1 = 0;
                    }
                    if (Number($(this).val()) >= 2) {
                        $simulator1.find(".i_day").text("days");
                    } else {
                        $simulator1.find(".i_day").text("day");
                    }
                    var tempenddate1 = new Date(saveMaturity1);
                    var newDate1 = new Date(saveMaturity1);

                    newDate1.setDate(tempenddate1.getDate() - addDays1);
                    $(".form-control_1").datepicker("setDate", newDate1);
                    sDays1 = addDays1 / 365;
                    then.assignmentResult(
                        sPrice1,
                        sEPrice1,
                        sDays1,
                        real_volatility1,
                        data,
                        exchangeratio,
                        Num
                    );
                });
                then.assignmentResult(
                    sPrice1,
                    sEPrice1,
                    sDays1,
                    real_volatility1,
                    data,
                    exchangeratio,
                    Num
                );
            } else if (Num == 2) {
                var $information2 = $('.Warrant_2').find(".information");
                var $simulator2 = $('.Warrant_2').find(".simulator");
                $('.Warrant_2').find(".calculator_call_name")
                    .find("span")
                    .text(data.type);
                // Warrant information
                var effective_gearing2 = data.effective_gearing;
                if (effective_gearing2 !== "N/A") {
                    effective_gearing2 =
                        this.$js.formatNumber(effective_gearing2, 1, 0) + " x";
                }
                var sensitivity2 = data.sensitivity;
                if (sensitivity2 !== "N/A") {
                    sensitivity2 = this.$js.formatNumber(sensitivity2, 1, 0) + " ticks";
                }
                $information2
                    .find(".underlying_price")
                    .text(
                        this.$js.curreryRetrun(data.underlying_curr) +
                        " " +
                        this.$js.formatNumber(data.underlying_price, 0, 1)
                    );
                $information2
                    .find(".exercise_price")
                    .text(
                        this.$js.curreryRetrun(data.underlying_curr) +
                        " " +
                        this.$js.formatNumber(data.exercise_price, 0, 1)
                    );
                $information2
                    .find(".conv_ratio")
                    .text(this.$js.formatNumber(data.conv_ratio, 4, 0));
                $information2.find(".maturity").text(data.maturity);
                $information2.find(".effective_gearing").text(effective_gearing2);
                $information2.find(".sensitivity").text(sensitivity2);

                // warrant Simulator
                // Underlying Target Price
                var sPrice2 = Number(data.underlying_price);
                var sEPrice2 = Number(data.exercise_price);
                var sDays2;
                var real_volatility2;
                if (data.v_implied_volatility) {
                    real_volatility2 = Number(data.v_implied_volatility);
                } else {
                    real_volatility2 = 0;
                }
                $simulator2
                    .find(".underlying_price")
                    .val(
                        this.$js.curreryRetrun(data.underlying_curr) +
                        " " +
                        this.$js.formatNumber(data.underlying_price, 0, 0)
                    );
                $simulator2.find(".underlying_price").on("input", function () {
                    if ($(this).val().length === 0) {
                        sPrice2 = 0;
                    } else {
                        sPrice2 = Number($(this).val());
                    }
                    var per2 =
                        ((sPrice2 - Number(data.underlying_price)) /
                            Number(data.underlying_price)) *
                        100;
                    $simulator2.find(".percentage").val(then.$js.formatNumber(per2, 1, 0));
                    $simulator2
                        .find(".percentage")
                        .width($simulator2.find(".percentage").val().length * 10);
                    then.assignmentResult(
                        sPrice2,
                        sEPrice2,
                        sDays2,
                        real_volatility2,
                        data,
                        exchangeratio,
                        Num
                    );
                });
                // Expect to sell on
                var todayDate2 = new Date(new Date().toDateString());
                var saveMaturity2 = data.maturity;
                var endDate2 = new Date(saveMaturity2);
                var selectdate2;
                // 日历选择器
                $(".form-control_2").datepicker("destroy");
                $(".form-control_2")
                    .datepicker({
                        autoclose: true,
                        dateFormat: "dd M y",
                        minDate: todayDate2,
                        maxDate: endDate2,
                        onSelect: function (dateText, inst) {
                            selectdate2 = new Date(dateText);
                            $(".form-control_2").datepicker("setDate", selectdate2);
                            $simulator2
                                .find(".days")
                                .val(parseInt((endDate2 - selectdate2) / (24 * 3600 * 1000)));
                            $simulator2
                                .find(".days")
                                .width($simulator2.find(".days").val().length * 10);
                            sDays2 = parseInt((endDate2 - selectdate2) / (24 * 3600 * 1000)) / 365;
                            then.assignmentResult(
                                sPrice2,
                                sEPrice2,
                                sDays2,
                                real_volatility2,
                                data,
                                exchangeratio,
                                Num
                            );
                        }
                    })
                    .datepicker("setDate", todayDate2);
                $('.Warrant_2').find("#ui-datepicker-div").css({ "overflow-y": "hidden" });
                // Default
                $simulator2.find(".days").val(this.intervalDate(todayDate2, endDate2));
                sDays2 = Number($simulator2.find(".days").val()) / 365;
                $simulator2
                    .find(".days")
                    .width($simulator2.find(".days").val().length * 10);
                $simulator2
                    .find(".percentage")
                    .val(
                        this.$js.formatNumber(
                            ((Number(data.underlying_price) - Number(data.underlying_price)) /
                                Number(data.underlying_price)) *
                            1,
                            1,
                            0
                        )
                    );
                $simulator2
                    .find(".percentage")
                    .width($simulator2.find(".percentage").val().length * 10);
                $simulator2.find(".percentage").on("input", function () {
                    var pNum2 = $(this).val();
                    if ($(this).val().length !== 0) {
                        $(this).width($(this).val().length * 10);
                    } else {
                        $(this).width(10);
                        pNum2 = 0;
                    }
                    var price2 = Number(data.underlying_price) * (1 + Number(pNum2) * 0.01);
                    $simulator2
                        .find(".underlying_price")
                        .val(
                            then.$js.curreryRetrun(data.underlying_curr) +
                            " " +
                            then.$js.formatNumber(price2, 3, 0)
                        );
                    sPrice2 = price2;
                    then.assignmentResult(
                        sPrice2,
                        sEPrice2,
                        sDays2,
                        real_volatility2,
                        data,
                        exchangeratio,
                        Num
                    );
                });
                if (Number($simulator2.find(".days").val()) >= 2) {
                    $simulator2.find(".i_day").text("days");
                } else {
                    $simulator2.find(".i_day").text("day");
                }
                $simulator2.find(".days").on("input", function () {
                    var addDays2 = parseInt($(this).val());
                    if ($(this).val().length !== 0) {
                        $(this).width($(this).val().length * 10);
                    } else {
                        $(this).width(10);
                        addDays2 = 0;
                    }
                    if (Number($(this).val()) >= 2) {
                        $simulator2.find(".i_day").text("days");
                    } else {
                        $simulator2.find(".i_day").text("day");
                    }
                    var tempenddate2 = new Date(saveMaturity2);
                    var newDate2 = new Date(saveMaturity2);

                    newDate2.setDate(tempenddate2.getDate() - addDays2);
                    $(".form-control_2").datepicker("setDate", newDate2);
                    sDays2 = addDays2 / 365;
                    then.assignmentResult(
                        sPrice2,
                        sEPrice2,
                        sDays2,
                        real_volatility2,
                        data,
                        exchangeratio,
                        Num
                    );
                });
                then.assignmentResult(
                    sPrice2,
                    sEPrice2,
                    sDays2,
                    real_volatility2,
                    data,
                    exchangeratio,
                    Num
                );
            }

        },
        // Result赋值
        assignmentResult: function (
            sPrice,
            sEPrice,
            sDays,
            real_volatility,
            data,
            exchangeratio,
            Num
        ) {

            if (Num == 1) {
                var $result1 = $('.Warrant_1').find(".result");
                var BID_NETCHNG1 = data.BID_NETCHNG;
                if (BID_NETCHNG1 === "") {
                    BID_NETCHNG1 = "N/A";
                } else if (BID_NETCHNG1 > 0) {
                    $result1.find(".BID_NETCHNG").addClass("add");
                    $result1.find(".BID_NETCHNG").removeClass("reduce");
                } else if (BID_NETCHNG1 < 0) {
                    $result1.find(".BID_NETCHNG").addClass("reduce");
                    $result1.find(".BID_NETCHNG").removeClass("add");
                } else if (Number(BID_NETCHNG1) == 0) {
                    $result1.find(".BID_NETCHNG").removeClass("add");
                    $result1.find(".BID_NETCHNG").removeClass("reduce");
                }
                var BID_PCTCHNG1 = this.$js.formatNumber(data.BID_PCTCHNG, 1, 0);
                if (BID_PCTCHNG1 === "") {
                    BID_PCTCHNG1 = "N/A";
                } else if (BID_PCTCHNG1 > 0) {
                    $result1.find(".BID_PCTCHNG").addClass("add");
                    $result1.find(".BID_PCTCHNG").removeClass("reduce");
                    BID_PCTCHNG1 = BID_PCTCHNG1 + "%";
                } else if (BID_PCTCHNG1 < 0) {
                    $result1.find(".BID_PCTCHNG").addClass("reduce");
                    $result1.find(".BID_PCTCHNG").removeClass("add");
                    BID_PCTCHNG1 = BID_PCTCHNG1 + "%";
                } else if (Number(BID_PCTCHNG1) == 0) {
                    $result1.find(".BID_PCTCHNG").removeClass("add");
                    $result1.find(".BID_PCTCHNG").removeClass("reduce");
                    BID_PCTCHNG1 = BID_PCTCHNG1 + "%";
                }
                // 货币间汇率的转换
                for (var e1 = 0, emax1 = exchangeratio.length; e1 < emax1; e1++) {
                    if (data.underlying_curr === exchangeratio[e1].currency) {
                        sPrice = parseFloat(sPrice) / parseFloat(exchangeratio[e1].rate);
                        sEPrice = parseFloat(sEPrice) / parseFloat(exchangeratio[e1].rate);
                    }
                }
                var CalBid1 = this.$js.blackScholes(
                    sPrice,
                    sEPrice,
                    sDays,
                    real_volatility / 100,
                    0.03,
                    data.type
                );
                CalBid1 = CalBid1 / Number(data.conv_ratio);
                var CalNetchng1 = this.$js.formatNumber(
                    Number(CalBid1) - Number(data.BID),
                    3,
                    0
                );
                if (CalNetchng1 > 0) {
                    $result1.find(".CalNetchng").addClass("add");
                    $result1.find(".CalNetchng").removeClass("reduce");
                    CalNetchng1 = "+" + CalNetchng1;
                } else if (CalNetchng1 < 0) {
                    $result1.find(".CalNetchng").addClass("reduce");
                    $result1.find(".CalNetchng").removeClass("add");
                } else if (CalNetchng1 == 0) {
                    $result1.find(".CalNetchng").removeClass("add");
                    $result1.find(".CalNetchng").removeClass("reduce");
                }
                var CalPctchng1 = this.$js.formatNumber(
                    (Number(CalNetchng1) / Number(data.BID)) * 100,
                    1,
                    0
                );
                if (Number(data.BID) !== 0) {
                    if (CalPctchng1 > 0) {
                        $result1.find(".CalPctchng").addClass("add");
                        $result1.find(".CalPctchng").removeClass("reduce");
                        CalPctchng1 = "+" + CalPctchng1 + "%";
                    } else if (CalPctchng1 < 0) {
                        $result1.find(".CalPctchng").addClass("reduce");
                        $result1.find(".CalPctchng").removeClass("add");
                        CalPctchng1 = CalPctchng1 + "%";
                    } else if (CalPctchng1 == 0) {
                        $result1.find(".CalPctchng").removeClass("add");
                        $result1.find(".CalPctchng").removeClass("reduce");
                        CalPctchng1 = CalPctchng1 + "%";
                    }
                } else {
                    CalPctchng1 = "N/A";
                }

                $result1
                    .find(".BID")
                    .text(
                        this.$js.curreryRetrun(data.currency) +
                        " " +
                        this.$js.formatNumber(data.BID, 0, 1)
                    );
                $result1.find(".BID_NETCHNG").text(this.$js.formatNumber(BID_NETCHNG1,0 ,1));
                $result1.find(".BID_PCTCHNG").text(BID_PCTCHNG1);
                $result1
                    .find(".CalBid")
                    .text(
                        this.$js.curreryRetrun(data.currency) +
                        " " +
                        this.$js.formatNumber(CalBid1, 0, 1)
                    );
                $result1.find(".CalNetchng").text(this.$js.formatNumber(CalNetchng1,0,1));
                $result1.find(".CalPctchng").text(CalPctchng1, 3, 0);
            } else if (Num == 2) {
                var $result2 = $('.Warrant_2').find(".result");
                var BID_NETCHNG2 = data.BID_NETCHNG;
                if (BID_NETCHNG2 === "") {
                    BID_NETCHNG2 = "N/A";
                } else if (BID_NETCHNG2 > 0) {
                    $result2.find(".BID_NETCHNG").addClass("add");
                    $result2.find(".BID_NETCHNG").removeClass("reduce");
                } else if (BID_NETCHNG2 < 0) {
                    $result2.find(".BID_NETCHNG").addClass("reduce");
                    $result2.find(".BID_NETCHNG").removeClass("add");
                } else if (Number(BID_NETCHNG2) == 0) {
                    $result2.find(".BID_NETCHNG").removeClass("add");
                    $result2.find(".BID_NETCHNG").removeClass("reduce");
                }
                var BID_PCTCHNG2 = this.$js.formatNumber(data.BID_PCTCHNG, 1, 0);
                if (BID_PCTCHNG2 === "") {
                    BID_PCTCHNG2 = "N/A";
                } else if (BID_PCTCHNG2 > 0) {
                    $result2.find(".BID_PCTCHNG").addClass("add");
                    $result2.find(".BID_PCTCHNG").removeClass("reduce");
                    BID_PCTCHNG2 = BID_PCTCHNG2 + "%";
                } else if (BID_PCTCHNG2 < 0) {
                    $result2.find(".BID_PCTCHNG").addClass("reduce");
                    $result2.find(".BID_PCTCHNG").removeClass("add");
                    BID_PCTCHNG2 = BID_PCTCHNG2 + "%";
                } else if (Number(BID_PCTCHNG2) == 0) {
                    $result2.find(".BID_PCTCHNG").removeClass("add");
                    $result2.find(".BID_PCTCHNG").removeClass("reduce");
                    BID_PCTCHNG2 = BID_PCTCHNG2 + "%";
                }
                // 货币间汇率的转换
                for (var e2 = 0, emax2 = exchangeratio.length; e2 < emax2; e2++) {
                    if (data.underlying_curr === exchangeratio[e2].currency) {
                        sPrice = parseFloat(sPrice) / parseFloat(exchangeratio[e2].rate);
                        sEPrice = parseFloat(sEPrice) / parseFloat(exchangeratio[e2].rate);
                    }
                }
                var CalBid2 = this.$js.blackScholes(
                    sPrice,
                    sEPrice,
                    sDays,
                    real_volatility / 100,
                    0.03,
                    data.type
                );
                CalBid2 = CalBid2 / Number(data.conv_ratio);
                var CalNetchng2 = this.$js.formatNumber(
                    Number(CalBid2) - Number(data.BID),
                    3,
                    0
                );
                if (CalNetchng2 > 0) {
                    $result2.find(".CalNetchng").addClass("add");
                    $result2.find(".CalNetchng").removeClass("reduce");
                    CalNetchng2 = "+" + CalNetchng2;
                } else if (CalNetchng2 < 0) {
                    $result2.find(".CalNetchng").addClass("reduce");
                    $result2.find(".CalNetchng").removeClass("add");
                } else if (CalNetchng2 == 0) {
                    $result2.find(".CalNetchng").removeClass("add");
                    $result2.find(".CalNetchng").removeClass("reduce");
                }
                var CalPctchng2 = this.$js.formatNumber(
                    (Number(CalNetchng2) / Number(data.BID)) * 100,
                    1,
                    0
                );
                if (Number(data.BID) !== 0) {
                    if (CalPctchng2 > 0) {
                        $result2.find(".CalPctchng").addClass("add");
                        $result2.find(".CalPctchng").removeClass("reduce");
                        CalPctchng2 = "+" + CalPctchng2 + "%";
                    } else if (CalPctchng2 < 0) {
                        $result2.find(".CalPctchng").addClass("reduce");
                        $result2.find(".CalPctchng").removeClass("add");
                        CalPctchng2 = CalPctchng2 + "%";
                    } else if (CalPctchng2 == 0) {
                        $result2.find(".CalPctchng").removeClass("add");
                        $result2.find(".CalPctchng").removeClass("reduce");
                        CalPctchng2 = CalPctchng2 + "%";
                    }
                } else {
                    CalPctchng2 = "N/A";
                }

                $result2
                    .find(".BID")
                    .text(
                        this.$js.curreryRetrun(data.currency) +
                        " " +
                        this.$js.formatNumber(data.BID, 0, 1)
                    );
                $result2.find(".BID_NETCHNG").text(this.$js.formatNumber(BID_NETCHNG2, 0, 1));
                $result2.find(".BID_PCTCHNG").text(BID_PCTCHNG2);
                $result2
                    .find(".CalBid")
                    .text(
                        this.$js.curreryRetrun(data.currency) +
                        " " +
                        this.$js.formatNumber(CalBid2, 0, 1)
                    );
                $result2.find(".CalNetchng").text(this.$js.formatNumber(CalNetchng2,0,1));
                $result2.find(".CalPctchng").text(CalPctchng2, 3, 0);
            }

        },
        // PriceMatrix赋值
        assignmentPriceMatrix: function (data, exchangeratio, Num) {
            let then = this;
            if (Num == 1) {
                var $valuation1 = $('.Warrant_1').find(".valuation");
                var $simulated1 = $('.Warrant_1').find(".simulated ");
                var $settlement_tow1 = $('.Warrant_1').find(".settlement_tow");
                var $solution1 = $('.Warrant_1').find(".solution");
                var close1 = [];
                var averageClosing1 = "";
                $simulated1
                    .find(".exercise_price")
                    .text(
                        this.$js.curreryRetrun(data.underlying_curr) +
                        " " +
                        this.$js.formatNumber(data.exercise_price, 0, 1)
                    );
                $simulated1
                    .find(".conv_ratio")
                    .text(this.$js.formatNumberNo(data.conv_ratio, 4));
                $.each(data, function (index, value) {
                    if (
                        index.indexOf(index.replace(/underlying_close/gi, "")) > 0 &&
                        index.indexOf(index.replace(/[^0-9]/gi, "")) > 0
                    ) {
                        close1[index.replace(/[^0-9]/gi, "") - 1] = parseFloat(value);
                        for (var i = 0; i < 5; i++) {
                            if (i === index.replace(/[^0-9]/gi, "") - 1) {
                                $valuation1
                                    .find("." + index)
                                    .text(
                                        data.underlying_curr +
                                        " " +
                                        then.$js.formatNumber(value, 0, 1)
                                    );
                            }
                        }
                    }
                });
                averageClosing1 = this.sumAvg(close1);
                $simulated1
                    .find(".average_closing")
                    .text(
                        data.underlying_curr +
                        " " +
                        this.$js.formatNumber(averageClosing1, 0, 1)
                    );
                $valuation1
                    .find(".average_closing")
                    .text(
                        data.underlying_curr +
                        " " +
                        this.$js.formatNumber(averageClosing1, 0, 1)
                    );
                var payoutPat1;
                if (data.type == "PUT") {
                    $('.Warrant_1').find(".simulated")
                        .find(".exercise_price")
                        .prev()
                        .html("Exercise Price");
                    $('.Warrant_1').find(".simulated")
                        .find(".average_closing")
                        .prev()
                        .html("- 5-day Average Closing");
                    $('.Warrant_1').find(".simulated")
                        .find(".exercise_price")
                        .parent()
                        .insertAfter($('.Warrant_1').find(".settlement").find(".simulated>li:first-child"));
                    payoutPat1 =
                        (Number(data.exercise_price) - Number(averageClosing1)) /
                        Number(data.conv_ratio);
                } else {
                    $('.Warrant_1').find(".simulated")
                        .find(".exercise_price")
                        .prev()
                        .html("- Exercise Price");
                    $('.Warrant_1').find(".simulated")
                        .find(".average_closing")
                        .prev()
                        .html("5-day Average Closing");
                    $('.Warrant_1').find(".simulated")
                        .find(".average_closing")
                        .parent()
                        .insertAfter($('.Warrant_1').find(".settlement").find(".simulated>li:first-child"));
                    payoutPat1 =
                        (Number(averageClosing1) - Number(data.exercise_price)) /
                        Number(data.conv_ratio);
                }
                // 货币间汇率的转换
                for (var e1 = 0, emax1 = exchangeratio.length; e1 < emax1; e1++) {
                    if (data.underlying_curr === exchangeratio[e1].currency) {
                        payoutPat1 = parseFloat(payoutPat1) / parseFloat(exchangeratio[e1].rate);
                    }
                }
                if (Number(payoutPat1) < 0) {
                    payoutPat1 = 0;
                }
                $settlement_tow1
                    .find(".payoutPat")
                    .text("Rp " + this.$js.formatNumber(payoutPat1, 0, 1));
                $solution1
                    .find(".total_payout")
                    .text("Rp " + Number(this.$js.formatNumber(payoutPat1, 0, 1)) * 0);
                $settlement_tow1
                    .find("input")
                    .val(0)
                    .on("input", function () {
                        var tNum1 = $(this).val();
                        if ($(this).val().length === 0) {
                            tNum1 = 0;
                        }
                        $solution1
                            .find(".total_payout")
                            .text(
                                "Rp " +
                                then.$js.formatNumber(
                                    Number(then.$js.formatNumber(payoutPat1, 0, 1)) * Number(tNum1),
                                    0,
                                    1
                                )
                            );
                    });
            } else if (Num == 2) {
                var $valuation2 = $('.Warrant_2').find(".valuation");
                var $simulated2 = $('.Warrant_2').find(".simulated ");
                var $settlement_tow2 = $('.Warrant_2').find(".settlement_tow");
                var $solution2 = $('.Warrant_2').find(".solution");
                var close2 = [];
                var averageClosing2 = "";
                $simulated2
                    .find(".exercise_price")
                    .text(
                        this.$js.curreryRetrun(data.underlying_curr) +
                        " " +
                        this.$js.formatNumber(data.exercise_price, 0, 1)
                    );
                $simulated2
                    .find(".conv_ratio")
                    .text(this.$js.formatNumberNo(data.conv_ratio, 4));
                $.each(data, function (index, value) {
                    if (
                        index.indexOf(index.replace(/underlying_close/gi, "")) > 0 &&
                        index.indexOf(index.replace(/[^0-9]/gi, "")) > 0
                    ) {
                        close2[index.replace(/[^0-9]/gi, "") - 1] = parseFloat(value);
                        for (var i = 0; i < 5; i++) {
                            if (i === index.replace(/[^0-9]/gi, "") - 1) {
                                $valuation2
                                    .find("." + index)
                                    .text(
                                        data.underlying_curr +
                                        " " +
                                        then.$js.formatNumber(value, 0, 1)
                                    );
                            }
                        }
                    }
                });
                averageClosing2 = this.sumAvg(close2);
                $simulated2
                    .find(".average_closing")
                    .text(
                        data.underlying_curr +
                        " " +
                        this.$js.formatNumber(averageClosing2, 0, 1)
                    );
                $valuation2
                    .find(".average_closing")
                    .text(
                        data.underlying_curr +
                        " " +
                        this.$js.formatNumber(averageClosing2, 0, 1)
                    );
                var payoutPat2;
                if (data.type == "PUT") {
                    $('.Warrant_2').find(".simulated")
                        .find(".exercise_price")
                        .prev()
                        .html("Exercise Price");
                    $('.Warrant_2').find(".simulated")
                        .find(".average_closing")
                        .prev()
                        .html("- 5-day Average Closing");
                    $('.Warrant_2').find(".simulated")
                        .find(".exercise_price")
                        .parent()
                        .insertAfter($('.Warrant_2').find(".settlement").find(".simulated>li:first-child"));
                    payoutPat =
                        (Number(data.exercise_price) - Number(averageClosing)) /
                        Number(data.conv_ratio);
                } else {
                    $('.Warrant_2').find(".simulated")
                        .find(".exercise_price")
                        .prev()
                        .html("- Exercise Price");
                    $('.Warrant_2').find(".simulated")
                        .find(".average_closing")
                        .prev()
                        .html("5-day Average Closing");
                    $('.Warrant_2').find(".simulated")
                        .find(".average_closing")
                        .parent()
                        .insertAfter($('.Warrant_2').find(".settlement").find(".simulated>li:first-child"));
                    payoutPat2 =
                        (Number(averageClosing2) - Number(data.exercise_price)) /
                        Number(data.conv_ratio);
                }
                // 货币间汇率的转换
                for (var e2 = 0, emax2 = exchangeratio.length; e2 < emax2; e2++) {
                    if (data.underlying_curr === exchangeratio[e2].currency) {
                        payoutPat2 = parseFloat(payoutPat2) / parseFloat(exchangeratio[e2].rate);
                    }
                }
                if (Number(payoutPat2) < 0) {
                    payoutPat2 = 0;
                }
                $settlement_tow2
                    .find(".payoutPat")
                    .text("Rp " + this.$js.formatNumber(payoutPat2, 0, 1));
                $solution2
                    .find(".total_payout")
                    .text("Rp " + Number(this.$js.formatNumber(payoutPat2, 0, 1)) * 0);
                $settlement_tow2
                    .find("input")
                    .val(0)
                    .on("input", function () {
                        var tNum2 = $(this).val();
                        if ($(this).val().length === 0) {
                            tNum2 = 0;
                        }
                        $solution2
                            .find(".total_payout")
                            .text(
                                "Rp " +
                                then.$js.formatNumber(
                                    Number(then.$js.formatNumber(payoutPat2, 0, 1)) * Number(tNum2),
                                    0,
                                    1
                                )
                            );
                    });
            }

        },
        //求平均数
        sumAvg: function (arr) {
            var len = arr.length;
            if (len == 0) {
                return 0;
            } else if (len == 1) {
                return arr[0];
            } else {
                return eval(arr.join("+")) / arr.length;
            }
        },
        // 时间转换
        switchDate: function (date) {
            var dataT, dd, mm, yy;
            var en_mon_arr = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ];
            dd = date.getDate();
            mm = en_mon_arr[date.getMonth()];
            yy = date.getFullYear() + "";
            dataT = dd + " " + mm + " " + yy.substring(4 - 2, 4);
            return dataT;
        },
        // 间时计算
        intervalDate: function (start, end) {
            var interval = (end - start) / (1 * 24 * 60 * 60 * 1000);
            return parseInt(interval);
        },
        getRiclist: function () {
            /*获取ric list*/
            let then = this;
            $.ajax({
                type: "GET",
                url: this.$js.webservice + "GetRICList",
                data: { token: "webkey" },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        then.seekRicData(data.ric);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        seekRicData: function (data) {
            let then = this;
            this.$js.jqcombobox("#details_combobox_1");
            $("#details_combobox_1").combobox({
                select: function (event, ui) {

                    then.ricNmae1 = this.value;
                    then.GetMarketData(this.value, 1);
                    // console.log(ui.item.innerText.split(" ")[1].split("(")[1].split(")")[0]);
                    $('meta[name="keywords"]').attr('content', "warrant calculator, warrant terms, RHB, malaysia warrants," + ui.item.innerText.split(" ")[0] + ',' + ui.item.innerText.split(" ")[1].split("(")[1].split(")")[0]);
                    $("#details_combobox_1").parent().find('input').blur()
                }
            });
            this.$js.jqcombobox("#details_combobox_2");
            $("#details_combobox_2").combobox({
                select: function (event, ui) {

                    then.ricNmae2 = this.value;
                    then.GetMarketData(this.value, 2);
                    // console.log(ui.item.innerText.split(" ")[1].split("(")[1].split(")")[0]);
                    $('meta[name="keywords"]').attr('content', "warrant calculator, warrant terms, RHB, malaysia warrants," + ui.item.innerText.split(" ")[0] + ',' + ui.item.innerText.split(" ")[1].split("(")[1].split(")")[0]);
                    $("#details_combobox_2").parent().find('input').blur()
                }
            });
            this.$js.clickEliminate(".ui_widget");
            var ricTxt = "";
            for (var r = 0, max = data.length; r < max; r++) {
                ricTxt +=
                    '<option value="' +
                    data[r].ric +
                    '">' +
                    data[r].dsply_nmll +
                    " (" +
                    data[r].ticker +
                    ")" +
                    "</option > ";
            }
            $("#details_combobox_1").append(ricTxt);
            $("#details_combobox_2").append(ricTxt);
            this.ricNmae1 = data[0].ric;
            this.ricNmae2 = data[1].ric;
            this.GetMarketData(data[0].ric, 1);
            this.GetMarketData(data[1].ric, 2);
            $('.Warrant_1').find(".ui_widget")
                .find("input")
                .val(data[0].dsply_nmll + " (" + data[0].ticker + ")");
            this._info(data[0], 1);
            $('.Warrant_2').find(".ui_widget")
                .find("input")
                .val(data[1].dsply_nmll + " (" + data[1].ticker + ")");
            this._info(data[1], 2);
            $('meta[name="keywords"]').attr('content', "warrant calculator, warrant terms, RHB, malaysia warrants," + data[0].dsply_nmll + ',' + data[0].ticker);
        }
    }
};